// Customizations
@import '@components/UIElements/Progress/custom.progress';
@import '@components/Player/PlayerElements/Footer/Playback/custom.playback';
@import '@components/UIElements/Films/FilmCard/custom.card';
@import '@components/B2bAuth/custom.b2b.auth';

// Common
.custom_element_background_gradient_uytsnh {
  background: $color-brand-gradient;
}

.custom_element_background_gradient_imyhsf {
  background: $color-brand-gradient !important;
}

.custom_element_color_wtyshd {
  color: $color-brand;
}

.custom_border_jsihvj {
  border: 0.1rem solid $color-brand !important;
}

.custom_outline_yhsrtd {
  box-shadow: 0 0 0 0.2rem $color-brand;
}

.custom_outline_thick_yhsrtd {
  box-shadow: 0 0 0 0.5rem $color-brand;
}

.custom_icon_fill_erpsip {
  filter: $color-brand-fill-icon;
}
