@import "src/styles/themes/omegatv/_vars.scss";
@import './animation';

.loader {
  display: flex;
  justify-content: center;

  &Fullscreen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &VideoLoading {
    z-index: 2;
  }

  &DotDefault {
    display: inline-flex;
    width: 1em;
    height: 1em;
    background-color: rgba(255, 255, 255, 15%);
    border-radius: 100%;

    &:not(:first-child) {
      margin-left: 0.6em;
    }

    &:nth-child(1) {
      animation: dots 1.3s 0s linear infinite,
        color-dots 1.3s 0s linear infinite;
    }

    &:nth-child(2) {
      animation: dots 1.3s 0.2s linear infinite,
        color-dots 1.3s 0.2s linear infinite;
    }

    &:nth-child(3) {
      animation: dots 1.3s 0.4s linear infinite,
        color-dots 1.3s 0.4s linear infinite;
    }

    &:nth-child(4) {
      animation: dots 1.3s 0.6s linear infinite,
        color-dots 1.3s 0.6s linear infinite;
    }

    &:nth-child(5) {
      animation: dots 1.3s 0.8s linear infinite,
        color-dots 1.3s 0.8s linear infinite;
    }
  }

  &DotBlack {
    display: inline-flex;
    width: 1em;
    height: 1em;
    background-color: rgba(255, 255, 255, 15%);
    border-radius: 100%;

    &:not(:first-child) {
      margin-left: 0.6em;
    }

    &:nth-child(1) {
      animation: dots 1.3s 0s linear infinite,
        color-dots-black 1.3s 0s linear infinite;
    }

    &:nth-child(2) {
      animation: dots 1.3s 0.2s linear infinite,
        color-dots-black 1.3s 0.2s linear infinite;
    }

    &:nth-child(3) {
      animation: dots 1.3s 0.4s linear infinite,
        color-dots-black 1.3s 0.4s linear infinite;
    }

    &:nth-child(4) {
      animation: dots 1.3s 0.6s linear infinite,
        color-dots-black 1.3s 0.6s linear infinite;
    }

    &:nth-child(5) {
      animation: dots 1.3s 0.8s linear infinite,
        color-dots-black 1.3s 0.8s linear infinite;
    }
  }
}
