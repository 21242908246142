@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';
@import '@styles/mixins';

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  &Icon {
    width: 4.2em;
    height: auto;

    &Wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 8.5em;
      min-height: 8.5em;
      margin-bottom: 2em;
      padding: 2em;
      background-color: $color-dark-gray;
      border-radius: $border-radius;
    }

    @include up(xxl) {
      font-size: 1em;
    }
  }

  &Text {
    font-weight: 700;
    font-size: 2.8em;
    text-transform: uppercase;
  }
}
