/* stylelint-disable-next-line selector-class-pattern */
.timeshift-range-track {
  background: $color-brand-gradient;
}

/* stylelint-disable-next-line selector-class-pattern */
.timeshift-range-handle {
  z-index: 1;
  background: $color-brand-gradient;
}

/* stylelint-disable-next-line selector-class-pattern */
.live-range-track {
  z-index: -1;
  background-color: rgba(255, 255, 255, 50%);
}
