@keyframes dots {
  0% {
    transform: translate(0, 0);
  }

  16% {
    transform: translate(0, -0.5em);
  }

  33% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes color-dots {
  0% {
    background-color: rgba(255, 255, 255, 15%);
  }

  16% {
    background-color: #ffffff;
  }

  33% {
    background-color: rgba(255, 255, 255, 15%);
  }

  100% {
    background-color: rgba(255, 255, 255, 15%);
  }
}

@keyframes color-dots-black {
  0% {
    background-color: rgba(0, 0, 0, 20%);
  }

  16% {
    background-color: #000000;
  }

  33% {
    background-color: rgba(0, 0, 0, 20%);
  }

  100% {
    background-color: rgba(0, 0, 0, 20%);
  }
}
