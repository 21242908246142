@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';

.fullScreenLoader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: $color-black;

  &Wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
