@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';

.infoDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &Btn {
    min-width: 22em;
    margin-top: 5em;
  }
}
