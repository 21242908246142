/* ---Colors--- */
$color-primary: #5d54e2;
$color-green: #55b920;
$color-red: #b92020;
$color-white: #ffffff;
$color-yellow: #ffd344;
$color-aqua: #00a8bf;
$color-black: #000000;
$color-light-black: #111111;
$color-dark-gray: #222222;
$color-light-gray: #777777;
$color-extra-light-gray: #e7e7e7;
$color-gradient: linear-gradient(to right, #5645fe, #7940ef);
$color-skeleton: linear-gradient(to right, #292929, #464646);
$color-filter-white: brightness(0) saturate(100%) invert(100%) sepia(100%)
  saturate(0%) hue-rotate(188deg) brightness(107%) contrast(101%);
$color-filter-black: brightness(0) saturate(100%) invert(8%) sepia(43%)
  saturate(0%) hue-rotate(166deg) brightness(98%) contrast(89%);
$color-bg-black-transparent: rgba(0, 0, 0, 90%);
$color-filter-light-gray: brightness(0) saturate(100%) invert(55%) sepia(0%)
  saturate(834%) hue-rotate(211deg) brightness(86%) contrast(100%);

/* ---Sizes--- */
$border-radius: 1.6em;
$skeleton-border-radius: 0.5em;
