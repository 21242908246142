// Breakpoints
$breakpoints: (
  xs: 360,
  // (mobile)
  md: 768,
  // (tablet)
  lg: 1280,
  // (720 TV)
  xl: 1440,
  // (retina laptop)
  xxl: 1920,
  // (FullHD TV)
  xxxl: 2560,
  // (2K TV)
  xxxxl: 3840,
  // (4K TV)
);
$orientations: (
  landscape: 'landscape',
  portrait: 'portrait',
);

@mixin up($breakpoint, $orientation: null) {
  @if map-has-key($breakpoints, $breakpoint) {
    /* stylelint-disable-next-line scss/no-global-function-names */
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @if $orientation {
      @if map-has-key($orientations, $orientation) {
        /* stylelint-disable-next-line scss/no-global-function-names */
        $orientation-value: map-get($orientations, $orientation);
        @media (min-width: calc($breakpoint-value / 16) + em) and (orientation: $orientation-value) {
          @content;
        }
      } @else {
        @warn 'Invalid orientation: #{$orientation}. Try for orientations: "landscape" or "portrait"';
      }
    } @else {
      @media (min-width: calc($breakpoint-value / 16) + em) {
        @content;
      }
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}. Try breakpoints: "xs", "md", "lg", "xl", "xxl", "xxxl", "xxxxl"';
  }
}

// Hide scrollbar
@mixin hide-scrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

// Marquee animation
@mixin marquee($animation, $duration) {
  animation: $animation $duration + s infinite linear;
}

// Scrollbar
@mixin scrollbar($thumb-color: $color-white, $track-color: $color-dark-gray) {
  /* stylelint-disable-next-line function-url-quotes */
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    transition: scrollbar-color 0.3s;

    &:hover {
      scrollbar-color: $thumb-color $track-color;
    }
  }

  @supports (-webkit-appearance: none) {
    &::-webkit-scrollbar {
      width: 1em;
      height: 1em;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 1em;
    }

    &:hover::-webkit-scrollbar-thumb {
      background: $thumb-color;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 1em;
    }

    &:hover::-webkit-scrollbar-track {
      background: $track-color;
    }
  }
}

@mixin empty-element($value1, $value2) {
  & > :not(:first-child) {
    margin-left: $value1;
  }

  & > :last-child {
    min-width: $value2;
    margin-left: $value2 !important;
  }
}
