@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';
@import '@styles/mixins';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5.5rem;
  padding: 1.8em 2em;
  color: inherit;
  font-weight: 600;
  background: $color-dark-gray;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;

  &Default {
    padding: 0.9em 1.1em;
    font-size: 2.8em;
    border-radius: 0.6em;
  }

  &BgTransparent {
    background: transparent;
  }

  &:disabled {
    cursor: auto;

    & > * {
      opacity: 0.5;
    }
  }

  &Reset {
    border: none;
    outline: none;
    cursor: pointer;
  }

  &Active {
    color: $color-white;
  }

  @include up(xxl) {
    min-height: 6.82rem;
  }

  &Text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.8em;
  }

  &Hover {
    color: $color-dark-gray !important;
    background: $color-white !important;

    & img {
      filter: $color-filter-black;
    }
  }
}

.svgBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  border-radius: 1.6em;
  outline: none;
  cursor: pointer;

  &Hover {
    color: $color-dark-gray !important;
    background: $color-white !important;

    & > img {
      filter: $color-filter-black;
    }
  }

  &:disabled {
    cursor: auto;

    & > * {
      opacity: 0.5;
    }
  }
}
