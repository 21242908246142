@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';
@import '@styles/mixins';

.dialog {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: $color-bg-black-transparent;
  cursor: default;

  &Wrapper {
    width: 46em;
    max-width: 46em;
  }

  &Header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Icon {
      width: 4.2em;
      height: auto;

      &Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 8.5em;
        min-height: 8.5em;
        margin-bottom: 2em;
        padding: 2em;
        background-color: $color-dark-gray;
        border-radius: $border-radius;
      }

      @include up(xxl) {
        font-size: 1em;
      }
    }

    &Title {
      font-weight: 700;
      font-size: 2.8em;
      text-transform: uppercase;
    }
  }

  &Body {
    margin-top: 5em;
  }

  &Actions {
    display: flex;
    justify-content: center;
    margin-top: 5em;

    &Item {
      min-width: 22em;
      max-width: 30em;

      &:not(:first-child) {
        margin-left: 2em;
      }
    }
  }
}
