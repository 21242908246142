@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';
@import '@styles/mixins';

.wrapper {
  position: relative;
  width: 100vw;
  min-width: 1280px;
  height: 100vh;
  overflow: hidden;
}

.tvWrapper {
  position: relative;
  width: 100vw;
  height: 720px;
  overflow: hidden;

  @include up(xxl) {
    height: 1080px;
  }

  @include up(xxxxl) {
    height: 2160px;
  }
}

.minW1280 {
  min-width: 1280px;
}

.video {
  position: fixed;
  width: 100%;
  height: 100%;
}

.fullScreenLoader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.skeleton {
  display: inline-flex;
  flex-shrink: 0;
  background: $color-skeleton;
  border-radius: $skeleton-border-radius;
}

.hoverInvert {
  background: $color-white !important;

  & > * {
    filter: invert(1);
  }
}

.hideScrollbar {
  @include hide-scrollbar;
}

// QR code frames
.frameTopLeft {
  position: absolute;
  top: 0;
  left: 0;
}

.frameTopRight {
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(-1, 1);
}

.frameBottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(1, -1);
}

.frameBottomRight {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: scale(-1, -1);
}

.flipIcon {
  transform: rotate(180deg);
}

/* stylelint-disable-next-line selector-class-pattern */
.grecaptcha-badge {
  visibility: hidden;
}

.topContainerShadow {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 5em;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0%),
      rgba(0, 0, 0, 30%),
      rgba(0, 0, 0, 70%),
      rgba(0, 0, 0, 90%),
      rgba(0, 0, 0, 100%)
    );
    content: '';
  }
}

.rightContainerShadow {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 5em;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0%),
      rgba(0, 0, 0, 30%),
      rgba(0, 0, 0, 70%),
      rgba(0, 0, 0, 90%),
      rgba(0, 0, 0, 100%)
    );
    content: '';
  }
}

.scrollBarDark {
  @include scrollbar($color-white, $color-dark-gray);
}

.scrollBarLight {
  @include scrollbar($color-dark-gray, $color-extra-light-gray);
}
