@import "src/styles/themes/omegatv/_vars.scss";
@import '@styles/vars';
@import '@styles/mixins';

.message {
  display: flex;
  justify-content: center;
  padding: 3em;
  border: 0.1rem solid $color-dark-gray;
  border-radius: $border-radius;

  &Text {
    color: $color-white;
    font-size: 2.4em;
    line-height: normal;
    text-align: center;
  }
}
